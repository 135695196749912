import { graphql, Link } from 'gatsby'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import { Box, Flex } from '@rebass/emotion'

import {
  Button,
  CurveBox,
  EyeBrowText,
  HeroHeadline,
  LinkText,
  Paragraph,
  SubHeadline,
} from '../components/atoms'
import { Layout } from '../components/layouts/Layout'
import {
  ConstrainedWidthContainer,
  GradientHeroContainer,
  MediaCard,
  SelectorGroup,
} from '../components/molecules'
import { GetInTouch, SEO } from '../components/organisms'
import FacebookLogo from '../images/Facebook.svg'
import InstagramLogo from '../images/Instagram.svg'
import TwitterLogo from '../images/Twitter.svg'
import YapstoneIconSvg from '../images/yapstone-icon.svg'
import { Mq } from '../styles/settings/theme'
import styled from '../styles/styled'

const CurveContainer = styled(Box)`
  display: none;
  position: relative;

  ${Mq.sm} {
    display: block;
  }
`

const PRSubHeadline = styled(SubHeadline)`
  color: ${props => props.theme.colors.purple};
`

const SocialIcon = styled.a`
  margin: 0 1rem;
`

const StyledCurveBox = styled(CurveBox)`
  display: none;

  ${Mq.sm} {
    transform: translateY(-132%);
    display: block;
  }
`

const YapstoneIcon = styled(Box)`
  background-image: url('${YapstoneIconSvg}');
  height: 150px;
  width: 150px;
  position: absolute;
  left: 50%;
  top: -175px;
  transform: translateX(-50%);
  z-index: 1;
`

const HeroEyebrow = styled(EyeBrowText)`
  ${Mq.sm} {
    color: ${props => props.theme.colors.white};
  }
`

const MediaHeroHeadline = styled(HeroHeadline)`
  padding-bottom: 1rem;

  ${Mq.sm} {
    color: ${props => props.theme.colors.white};
  }
`

const HeroDescription = styled(Paragraph)`
  ${Mq.sm} {
    color: ${props => props.theme.colors.white};
  }
`

const HeroMobileImage = styled(Box)`
  background: no-repeat center url("${props => props.imageUrl}");
  background-size: cover;
  padding: 1rem;
  margin-bottom: 2rem;
  min-height: 13rem;

  ${Mq.sm} {
    display: none;
  }
`

const PanelsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem 3rem;

  ${Mq.md} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5rem 1.5rem;
  }
`

const ReadMoreButton = styled(Button)`
  width: 100%;

  ${Mq.sm} {
    margin-top: 2rem;
    width: auto;
  }
`

const ALL_CATEGORY = 'all'
const FEATURED_CATEGORY = 'featured'

const MediaPage = ({ data, location }) => {
  const categoryIdFromUrl = queryString.parse(location.search).category

  const categories = [
    { title: 'Featured', display_id: FEATURED_CATEGORY },
    ...data.allContentstackMediaCategories.nodes,
    { title: 'All', display_id: ALL_CATEGORY },
  ]

  const categoryIndex = categories.findIndex(
    category => category.display_id === categoryIdFromUrl,
  )

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(
    categoryIndex >= 0 ? categoryIndex : 0,
  )
  const [pagesToDisplay, setPagesToDisplay] = useState([])

  const selectorItems = categories.map((category, idx) => {
    return {
      text: category.title,
      onClick: () => setSelectedCategoryIndex(idx),
    }
  })

  const featuredArticle = data.allContentstackMediaPages.nodes.find(
    page => page.main_featured_hero_article,
  )

  useEffect(() => {
    const selectedCategoryDisplayId =
      categories[selectedCategoryIndex].display_id

    if (selectedCategoryDisplayId === ALL_CATEGORY) {
      setPagesToDisplay(data.allContentstackMediaPages.nodes)
    } else if (selectedCategoryDisplayId === FEATURED_CATEGORY) {
      setPagesToDisplay(
        data.allContentstackMediaPages.nodes.filter(page => page.featured),
      )
    } else {
      setPagesToDisplay(
        data.allContentstackMediaPages.nodes.filter(
          page =>
            page.category[0].display_id ===
            categories[selectedCategoryIndex].display_id,
        ),
      )
    }
  }, [selectedCategoryIndex])

  return (
    <Layout>
      <SEO
        title={data.contentstackMediaIndexPage.title}
        description={data.contentstackMediaIndexPage.seo_description}
      />

      <Box mb={[3, 4]}>
        <GradientHeroContainer imageUrl={featuredArticle.hero_image.url}>
          <ConstrainedWidthContainer width={[1]}>
            <HeroMobileImage imageUrl={featuredArticle.hero_image.url} />
            <Box mb={3}>
              <HeroEyebrow>
                {data.contentstackMediaIndexPage.featured_article_heading}
              </HeroEyebrow>
            </Box>

            <Box width={[1, 1, 1, 1, 11 / 12, 7 / 12]}>
              <MediaHeroHeadline>{featuredArticle.title}</MediaHeroHeadline>
            </Box>

            <Flex width={[1, 1, 1, 1, 7 / 12, 5 / 12]}>
              <HeroDescription>
                {featuredArticle.short_description}
              </HeroDescription>
            </Flex>

            <Link to={featuredArticle.url}>
              <ReadMoreButton>
                {data.contentstackMediaIndexPage.read_more_button_text}
              </ReadMoreButton>
            </Link>
          </ConstrainedWidthContainer>
        </GradientHeroContainer>
      </Box>

      <CurveContainer>
        <YapstoneIcon />
        <StyledCurveBox />
      </CurveContainer>

      <ConstrainedWidthContainer>
        <Box p={2} textAlign="center" mb={[0, 5]}>
          <SelectorGroup
            items={selectorItems}
            selectedIndex={selectedCategoryIndex}
          />
        </Box>

        <PanelsContainer mb={4}>
          {pagesToDisplay.map(page => (
            <Link key={page.url} to={page.url}>
              <MediaCard
                title={page.title}
                date={new Date(page.publish_date)}
                imageUrl={page.hero_image.url}
              />
            </Link>
          ))}
        </PanelsContainer>

        <Flex flexWrap="wrap" flexDirection="column" py={5}>
          <Box mx="auto" textAlign="center">
            <PRSubHeadline>
              {data.contentstackMediaIndexPage.pr_title}
            </PRSubHeadline>
          </Box>
          <Box mx="auto">
            <a href={data.contentstackMediaIndexPage.pr_link.url}>
              <LinkText>
                {data.contentstackMediaIndexPage.pr_link.display_text}
              </LinkText>
            </a>
          </Box>
          <Box mx="auto" mt={4}>
            <SocialIcon href={data.contentstackMediaIndexPage.facebook_url}>
              <img src={FacebookLogo} alt="Facebook Logo" />
            </SocialIcon>
            <SocialIcon href={data.contentstackMediaIndexPage.twitter_url}>
              <img src={TwitterLogo} alt="Twitter Logo" />
            </SocialIcon>
            <SocialIcon href={data.contentstackMediaIndexPage.instagram_url}>
              <img src={InstagramLogo} alt="Instagram Logo" />
            </SocialIcon>
          </Box>
        </Flex>
      </ConstrainedWidthContainer>

      <GetInTouch />
    </Layout>
  )
}

export default MediaPage

export const query = graphql`
  query {
    contentstackMediaIndexPage {
      title
      seo_description
      featured_article_heading
      read_more_button_text
      pr_title
      pr_link {
        display_text
        url
      }
      facebook_url
      twitter_url
      instagram_url
    }
    allContentstackMediaPages {
      nodes {
        main_featured_hero_article
        featured
        short_description
        url
        title
        publish_date
        hero_image {
          url
        }
        category {
          id
          title
          display_id
        }
      }
    }
    allContentstackMediaCategories {
      nodes {
        title
        display_id
      }
    }
  }
`
